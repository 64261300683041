import React, {FC} from "react";
import {Typography} from "antd";
import { ConversationStoryRow } from "./ConversationStoryRow";
import { TranscriptFeedbackRow } from "./TranscriptFeedbackRow";
import { InvestigationRow } from "./InvestigationRow";
import { DialogueChoicesRow } from "./DialogueChoicesRow";
import { TranscriptModal } from "../components/ConversationStory/TranscriptModal";
import { OrdersModal } from "../components/Investigation/OrdersModal";
import {Activity, DashboardView} from "../../../types";
import {LabelsProvider} from "../../../hooks/Labels";
import {GUTTER} from "../../../constants";
import {ChecklistFeedbackRow} from "./ChecklistFeedbackRow";
import {ConversationElicitRow} from "./ConversationElicitRow";

const DashboardViewFeedbackCardMapping: { [key in DashboardView]?: FC } = {
    [DashboardView.EXERCISE_CONVERSATION_STORY_COVERAGE]: ConversationStoryRow,
    [DashboardView.EXERCISE_CONVERSATION_ELICIT_PERFORMANCE]: ConversationElicitRow,
    [DashboardView.EXERCISE_TRANSCRIPT_COVERAGE]: TranscriptFeedbackRow,
    [DashboardView.EXERCISE_CHECKLIST_COVERAGE]: ChecklistFeedbackRow,
    [DashboardView.EXERCISE_INVESTIGATIONS_COVERAGE]: InvestigationRow,
    [DashboardView.EXERCISE_DIALOGUE_CHOICES_COVERAGE]: DialogueChoicesRow
}

const DashboardViewFeedbackModalMapping: { [key in DashboardView]?: FC } = {
    [DashboardView.EXERCISE_CONVERSATION_STORY_COVERAGE]: TranscriptModal,
    [DashboardView.EXERCISE_TRANSCRIPT_COVERAGE]: TranscriptModal,
    [DashboardView.EXERCISE_CHECKLIST_COVERAGE]: TranscriptModal,
    [DashboardView.EXERCISE_DIALOGUE_CHOICES_COVERAGE]: TranscriptModal,
    [DashboardView.EXERCISE_INVESTIGATIONS_COVERAGE]: OrdersModal
}

const {Title} = Typography;

export const renderAttemptsFeedback = (activity?: Activity) =>
    activity?.renderers.map((renderer) => {
        const FeedbackRow = DashboardViewFeedbackCardMapping[renderer.dashboard_view];
        return FeedbackRow ? <LabelsProvider key={renderer.id} activityRenderer={renderer.id}>
            <FeedbackRow/>
        </LabelsProvider> : null;
    })

export const renderStudentAttemptsFeedback = (activity?: Activity) =>
    activity?.renderers.map((renderer) => {
        const FeedbackRow = DashboardViewFeedbackCardMapping[renderer.dashboard_view];
        const FeedbackModal = DashboardViewFeedbackModalMapping[renderer.dashboard_view];
        return FeedbackRow ? <LabelsProvider key={renderer.id} activityRenderer={renderer.id}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Title level={3} style={{fontWeight: 400, marginTop: GUTTER[0]}}>{renderer.name}</Title>
                {FeedbackModal ? <FeedbackModal/> : null}
            </div>
            <FeedbackRow/>
        </LabelsProvider> : null;
    })