import React, {FC, Fragment, ReactElement, useMemo} from 'react';
import {useParams} from "react-router-dom";
import {useQuerySearchParams} from "../../../../config/queryClient";
import {Card, List, Table, Typography} from "antd";
import {StudentExerciseAttempt} from "../../../../types";
import {ColumnsType} from "antd/lib/table";
import {useLabels} from "../../../../hooks/Labels";
import {DATATABLE_VERTICAL_SCROLL} from "../../../../constants";

const {Text} = Typography;

const columns: ColumnsType<any> = [
    {
        title: 'Section',
        dataIndex: 'title',
        key: 'title'
    },
    {
        title: 'Score',
        dataIndex: 'score',
        key: 'score',
        render: value => value + '%'
    },
];

export const SectionRow: FC<any> = ({criteria, passed, description}): ReactElement => (
    <Fragment>
        <Text type={passed ? 'success' : 'danger'} strong>
            {description}
        </Text>
        <List
            dataSource={criteria}
            renderItem={(item: any) => (
                <List.Item style={{color: ''}}>
                    <Text type={item.score === 'pass' ? 'success' : 'danger'}>
                        {'- '}{item.reasoning}
                    </Text>
                </List.Item>
            )}
        />
    </Fragment>
)

export const AttemptFITPerformanceDataTableCard: FC = (): ReactElement => {
    const {t} = useLabels();
    const {attemptId} = useParams();

    const {data, isLoading} = useQuerySearchParams<StudentExerciseAttempt>(
        `/reports/student-exercise-attempts/${attemptId}/`, [], {
            defaultFilters: {
                fields: 'id,feedback'
            }
        })

    const sections = data?.feedback.stages[0].marking_feedback.sections || [];

    return (
        <Card title={t('section_performance_title')} className='data-table-card' style={{height: '100%'}}>
            <Table
                loading={isLoading}
                dataSource={sections}
                columns={columns}
                rowKey='title'
                pagination={false}
                expandable={{
                    expandedRowRender: record => <SectionRow
                        criteria={record.criteria}
                        description={record.description}
                        passed={record.score === 100}
                    />,
                    rowExpandable: () => true,
                }}
                scroll={DATATABLE_VERTICAL_SCROLL}
            />
        </Card>
    );
};
