import {DashboardView} from "../../../types";
import React, {ReactElement} from "react";
import {DialogueChoicesTab} from "./DialogueChoicesTab";
import {ConversationElicitTab} from "./ConversationElicitTab";
import {InvestigationsTab} from "./InvestigationsTab";
import {PhysicalExaminationTab} from "./PhysicalExaminationTab";
import {ConversationStoryTab} from "./ConversationStoryTab";
import {TranscriptFeedbackTab} from "./TranscriptFeedbackTab";
import {ChecklistFeedbackTab} from "./ChecklistFeedbackTab";

export const DashboardViewProductTabMapping: {[key in DashboardView]?: ReactElement} = {
    [DashboardView.EXERCISE_DIALOGUE_CHOICES_COVERAGE]: <DialogueChoicesTab/>,
    [DashboardView.EXERCISE_CONVERSATION_ELICIT_PERFORMANCE]: <ConversationElicitTab/>,
    [DashboardView.EXERCISE_INVESTIGATIONS_COVERAGE]: <InvestigationsTab/>,
    [DashboardView.EXERCISE_PHYSICAL_EXAM_COVERAGE]: <PhysicalExaminationTab/>,
    [DashboardView.EXERCISE_CONVERSATION_STORY_COVERAGE]: <ConversationStoryTab/>,
    [DashboardView.EXERCISE_TRANSCRIPT_COVERAGE]: <TranscriptFeedbackTab/>,
    [DashboardView.EXERCISE_CHECKLIST_COVERAGE]: <ChecklistFeedbackTab/>
}