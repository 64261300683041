import {FC, Fragment, ReactElement} from 'react';
import {useParams} from "react-router-dom";
import {Col, Row} from "antd";
import {BookOutlined, CheckCircleTwoTone, UserOutlined} from "@ant-design/icons";
import {PageHeader} from "../../components/PageHeader";
import {SpinContainer} from "../../components/SpinContainer";
import {DescriptionCard} from "./components/DescriptionCard";
import {ContentBreakdownCard} from "./components/ContentBreakdownCard";
import {Course} from "../../types";
import {ActivitiesDataTableCard, activityColumns} from "../../components/ActivitiesDataTableCard";
import {IconCard} from "../../components/IconCard";
import {CourseScoreHistogramCard} from "./components/CourseScoreHistogramCard";
import {useQuerySearchParams} from "../../config/queryClient";
import {GUTTER} from "../../constants";
import {QueryFilter} from "../../components/DataTable/Filters/types";
import {useTopFilterSelectionListener} from "../../hooks/TopFilterSelectionListener";
import {useLabels} from "../../hooks/Labels";

export const CoursePage: FC = (): ReactElement => {
    const {t} = useLabels();
    const {id} = useParams();
    useTopFilterSelectionListener(QueryFilter.COURSES, id);
    const {data, isLoading} = useQuerySearchParams<Course>(
        `/reports/courses/${id}/`,
        [QueryFilter.COHORTS]
    );

    if (isLoading) {
        return <SpinContainer/>
    }

    return (
        <Fragment>
            <PageHeader title={data?.title} icon={BookOutlined}/>
            <Row gutter={GUTTER}>
                <Col xs={24} xl={12}>
                    <DescriptionCard html={data?.description || 'N/A'}/>
                </Col>
                <Col xs={12} xl={6} style={{display: 'block'}}>
                    <IconCard
                        title={`${t('student_pl')} enrolled`}
                        value={data?.total_enrolled_students}
                        icon={<UserOutlined/>}
                    />
                </Col>
                <Col xs={12} xl={6} style={{display: 'block'}}>
                    <IconCard
                        title='Completion'
                        value={data?.completion_rate_percentage}
                        unit='%'
                        icon={<CheckCircleTwoTone />}
                    />
                </Col>
                <Col xs={24} xl={12}>
                    <CourseScoreHistogramCard
                        defaultFilters={{courses: id}}
                        searchParamFilters={[QueryFilter.COHORTS]}
                    />
                </Col>
                <Col xs={24} xl={12}>
                    <ContentBreakdownCard/>
                </Col>
                <Col xs={24} xl={24}>
                    <ActivitiesDataTableCard
                        columns={
                            activityColumns.filter(col => col.title !== 'Course')
                        }
                        defaultFilters={{courses: id || ''}}
                        searchParamFilters={[QueryFilter.COHORTS]}
                    />
                </Col>
            </Row>
        </Fragment>
    );
};