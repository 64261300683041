import React, {FC, ReactElement} from 'react';
import {GUTTER} from "../../../constants";
import {Col, Row} from "antd";
import {AverageScoreCard} from "../components/Generic/AverageScoreCard";
import {AverageTimeSpentCard} from "../components/Generic/AverageTimeSpentCard";

export const ChecklistFeedbackTab: FC = (): ReactElement => {
    return (
        <Row gutter={GUTTER}>
                <Col xs={6}>
                    <AverageScoreCard/>
                </Col>
                <Col xs={6}>
                    <AverageTimeSpentCard/>
                </Col>
        </Row>
    );
};