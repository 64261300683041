import {FC, ReactElement} from 'react';
import { GUTTER } from "../../../constants";
import {Card, Col, Row} from "antd";
import {useParams} from "react-router-dom";
import {Transcript} from "../components/ConversationStory/TranscriptModal";

export const ConversationElicitRow: FC = (): ReactElement => {
    const {attemptId} = useParams();
    return (
        <Row gutter={GUTTER}>
            <Col xs={24}>
                <Card style={{maxHeight: 500, overflowY: 'auto'}}>
                    <Row justify='center'>
                        { attemptId !== undefined && <Transcript attemptId={attemptId!}/>}
                    </Row>
                </Card>
            </Col>
        </Row>
    );
};